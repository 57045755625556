hr { 
	display: block; 
	margin: 1em 0; 
	padding: 0;
	height: 1px; 
	border: 0; 
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #fff; 
}
/* Figures and images ==================================== */
figure {
	margin-bottom: $indent-var;
}
article img {
	max-width: 100%;
	height: auto;
	border-width: 0; 
	vertical-align: middle; 
	-ms-interpolation-mode: bicubic; 
}
svg:not(:root) {
		overflow: hidden; 
}
.half {
	@include container;
	@include clearfix;
	@media #{$micro} {
		img {
			@include grid(12,6);
		}
		figcaption {
			@include grid(12,12);
		}
	}
}
.third {
	@include container;
	@include clearfix;
	@media #{$micro} {
		img {
			@include grid(12,4);
		}
		figcaption {
			@include grid(12,12);
		}
	}
}
/* Buttons ============================================== */
.btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 20px;
  @include font-rem(14);
  background-color: $primary;
  color: $white;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: $primary;
  @include rounded(3px);
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
}
label.btn-more{
  display: inline-block;
  transition: .1s;
  cursor: pointer;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 1px 5px 3px 5px;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: $primary;
  @include font-rem(10);
  background-color: $primary;
  color: $white;
  @include rounded(3px);
  //&:visited {
  //  color: $white;
  //}
  &:hover {
  background-color: $white;
  color: $primary;
  }
  &:active{
  background-color: $white;
  color: $primary;
  }
}
input.btn-more{
    display:none;
}
.collapse{
    visibility: hidden;
}
input.btn-more + div{
    visibility: hidden;
}
input.btn-more:checked~.collapse{
    visibility: visible;
}

 input.btn-more:checked + label{
    background-color: $white;
    color: $primary;
}

input.btn-more:checked + label:hover{
    background-color: $primary;
    color: $white;
}
// When JavaScript is disabled, we hide the more toggle button
.no-js .btn-more{
	display: none;
}
// When JavaScript is disabled, we show the more content 
.no-js input.btn-more + *{
    visibility: visible;
}

.btn-success {
  background-color: $success;
  color: $white;
  border-color: $success;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $success;
  }
}
.btn-warning {
  background-color: $warning;
  color: $white;
  border-color: $warning;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $warning;
  }
}
.btn-danger {
  background-color: $danger;
  color: $white;
  border-color: $danger;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $danger;
  }
}
.btn-info {
  background-color: $info;
  color: $white;
  border-color: $info;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $info;
  }
}
/* Well ================================================= */
.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	@include rounded(4px);
	@include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.05));
}

.more {
    margin-top: 10px;
}
