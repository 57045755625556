// TYPOGRAPHY ================================================
$doc-font-size: 14;
$doc-line-height: 30;
$paragraph-indent: true !default;
$base-font: 'PT Serif', serif;
$heading-font: 'PT Sans Narrow', sans-serif;
$code-font: Monaco, "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
$alt-font: $base-font;

// COLOR ======================================================
$bodycolor      : #FFFFFF;
$body-color     : $bodycolor;
$textcolor      : #333332;
$text-color     : $textcolor;
$border-color   : $text-color;
$basecolor 		  : #343434;	  
$compcolor 		  : spin($basecolor, 180); 
$bordercolor    : $basecolor;
$white          : #fff;
$black          : #111;
$accentcolor    : $black;

// Buttons
$primary			: $black;
$success			: #5cb85c;
$warning			: #dd8338;
$danger				: #C64537;
$info				  : #308cbc;

// Links
$linkcolor 		    : #343434;
$link-color       : $linkcolor;
$linkcolorhover 	: darken($linkcolor, 10);
$linkcolorvisited : lighten($linkcolorhover, 20);
$linkcolorfocus 	: darken($linkcolorvisited, 10);

// Media Queries
$micro            : "only screen and (min-width: 30em)";
$small            : "only screen and (min-width: 37.5em)";
$medium           : "only screen and (min-width: 48em)";
$large            : "only screen and (min-width: 62em)";
$x-large          : "only screen and (min-width: 86.375em)";
